import React, { useState, useEffect } from 'react';
import "./Timer.css"

const Timer = () => {
  const [targetDate, setTargetDate] = useState(new Date('2024-08-04T10:00:00'));
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  function calculateTimeRemaining() {
    const difference = +targetDate - +new Date();
    let time = {};

    if (difference > 0) {
      time = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    } else {
      time = { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    return time;
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => clearInterval(interval);
  }, []);
  return (
    <div className='timer_container'>
        <div className='tbb'>To big bang</div>
        <div className='data'>
            <div className='time'>Days: <br /> {timeRemaining.days}</div>
            <div className='time'>Hours: <br /> {timeRemaining.hours}</div>
            <div className='time'>Minutes: <br /> {timeRemaining.minutes}</div>
            <div className='time'>Seconds: <br /> {timeRemaining.seconds}</div>
        </div>
    </div>
  );
};

export default Timer;
