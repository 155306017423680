import React from 'react';
import "./App.css";
import ParticlesComponent from './particles/particles';
import Timer from './timer/Timer';

class App extends React.Component {
  render = () => {
    return (
      <div className='body'>
        <ParticlesComponent />
        <div className="card">
          <div className="content">
            <div className="title-holder">
              <h1>Get ready for the change.</h1>
              <h2 className='title'>coming soon</h2>
              <Timer/>
            </div>
          </div>
        </div>  
      </div>
    );
  }
}

export default App;